import { useReducer, useRef } from 'react';
import { css, styled } from '../../stitches.config';
import { SearchIcon, MenuIcon, CartIcon, ProfileIcon } from '../../Atoms/Icons';
import loadable from '@loadable/component';
import menuReducer from '../Header/Reducers/menuReducer';
import miniCartReducer from '../Header/Reducers/miniCartReducer';
import {
  EventDispatcher,
  ON_MOBILE_QUICKSEARCH_TOGGLE,
} from '../../Shared/Common/EventDispatcher';
import { GetCart } from '../../Pages/CartPage/Cart';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { useUserStateData } from '../../Shared/UserContextProvider/UserContextProvider';
import KexLink from '../../Kex/KexLink';

const NavigationMenu = loadable(
  () => import('../NavigationMenu/NavigationMenu')
);

const MiniCart = loadable(() => import('../MiniCart/MiniCart'));

function MobileMenu() {
  const {
    staticPages: { myPage, loginPage },
  } = useAppSettingsData();
  const [{ menuIsOpen, mountMenu }, menuDispatch] = useReducer(menuReducer, {
    menuIsOpen: false,
    mountMenu: true,
  });
  const [{ miniCartIsOpen, mountMiniCart }, miniCartDispatch] = useReducer(
    miniCartReducer,
    {
      miniCartIsOpen: false,
      mountMiniCart: true,
    }
  );
  const {
    'common/menu': menuText,
    'common/search': searchText,
    'common/close': closeText,
  } = useTranslationData();
  const { authenticated } = useUserStateData();
  const { cart, isLoading } = GetCart();
  const nbrRef = useRef<HTMLSpanElement>(null);
  const isEmpty = cart.numberOfItems === 0;

  return (
    <>
      <Menu>
        <List>
          <MenuWrapper>
            <ButtonCenter
              aria-label={menuText}
              onClick={() =>
                menuDispatch(menuIsOpen ? 'closeMenu' : 'mountAndOpen')
              }
            >
              <MenuIcon isOpen={menuIsOpen} css={StyledMenuIcon} />
              {menuIsOpen && <MenuText>{closeText}</MenuText>}
            </ButtonCenter>
          </MenuWrapper>
          <RightSection>
            <div>
              <ButtonLeft
                aria-label={searchText}
                onClick={() =>
                  EventDispatcher.dispatch(ON_MOBILE_QUICKSEARCH_TOGGLE, null)
                }
              >
                <StyledSearchIcon size="l" />
              </ButtonLeft>
            </div>
            <div>
              <ButtonUser>
                <KexLink href={!authenticated ? loginPage : myPage}>
                  <ProfileIcon size="l" />
                </KexLink>
              </ButtonUser>
            </div>
            <div>
              <CartButton
                onClick={() => {
                  miniCartDispatch(
                    miniCartIsOpen ? 'closeMiniCart' : 'mountAndOpen'
                  );
                }}
              >
                <StyledCartIcon size="l" />
                {!isLoading && !isEmpty && (
                  <CartItemsNotifier>
                    <span ref={nbrRef}>{cart.numberOfItems}</span>
                  </CartItemsNotifier>
                )}
              </CartButton>
            </div>
          </RightSection>
        </List>
      </Menu>
      {mountMenu && (
        <NavigationMenu
          isOpen={menuIsOpen}
          onMenuClose={() => menuDispatch('closeMenu')}
        />
      )}
      {mountMiniCart && (
        <MiniCart
          isOpen={miniCartIsOpen}
          closeMiniCart={() => miniCartDispatch('closeMiniCart')}
        />
      )}
    </>
  );
}

const buttonStyling = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minW: '40px',
  minH: '40px',
  border: 0,
};

const Menu = styled('div', {
  position: 'fixed',
  b: 6,
  backgroundColor: '$menuBackgroundPrimary',
  maxWidth: '$mobileMenuMaxWidth',
  w: '100%',
  l: '50%',
  transform: 'translateX(-50%)',
  br: '40px',
  zIndex: '$Header',
  p: '8px 16px 8px 24px',
  boxShadow: '0px 4px 57px rgba(0, 0, 0, 0.1)',
});

const MenuText = styled('span', {
  fontSize: '12px',
  lineHeight: '21px',
  letterSpacing: '1px',
  ml: 2,
  mt: 1,
});

const List = styled('ul', {
  display: 'flex',
  justifyContent: 'space-between',
  maxH: '100%',
});

const MenuWrapper = styled('li', {});

const RightSection = styled('li', {
  display: 'flex',
  alignItems: 'center',
  g: '8px',
});

const ButtonLeft = styled('button', buttonStyling);

const ButtonCenter = styled('button', {
  ...buttonStyling,
  justifyContent: 'center',
});

const ButtonUser = styled('button', {
  ...buttonStyling,
  justifyContent: 'center',
});

const CartButton = styled('button', {
  ...buttonStyling,
  justifyContent: 'flex-start',
  position: 'relative',
});

const StyledSearchIcon = styled(SearchIcon, {
  w: '24px',
  h: '24px',
});

const StyledMenuIcon = css({
  w: 4,
  h: 3,
  mr: 2,
});

const StyledCartIcon = styled(CartIcon, {
  w: '24px',
  h: '24px',
  fill: '$fillPrimary',
});

const CartItemsNotifier = styled('span', {
  position: 'absolute',
  t: 0,
  r: -2,
  backgroundColor: '$backgroundAccent',
  br: 2.625,
  fs: 5,
  fontWeight: '$fw700',
  wh: 5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflowY: 'hidden',
});

export default MobileMenu;
