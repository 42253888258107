import { useState, useEffect } from 'react';
import KexLink from '../../../Kex/KexLink';
import { styled } from '../../../stitches.config';
import Image from '../../../Atoms/Image/Image';
import { useAppSettingsData } from '../../../Shared/Providers/AppSettingsProvider';
import MobileQuickSearch from '../../MobileQuickSearch/MobileQuickSearch';
import MobileMenu from '../../MobileMenu/MobileMenu';
import { useHeaderData } from '../Header';
import LanguagePicker from '../../../Molecules/LanguagePicker/LanguagePicker';
import { useTranslationData } from '../../../Shared/Providers/TranslationProvider';
import ContentContainer from '../../../Molecules/ContentContainer/ContentContainer';

function MobileHeader() {
  const { languageRoute } = useAppSettingsData();
  const { languagePicker, logo, topResellerLink } = useHeaderData();
  const { 'header/reseller': resellerText } = useTranslationData();
  return (
    <>
      <Placeholder />
      <HeaderTag id="header">
        <Wrapper>
          <TopSection>
            <TopContentContainer>
              <TopColumn>
                {topResellerLink?.href && (
                  <div>
                    <ResellerLink href={topResellerLink.href}>
                      {resellerText}
                    </ResellerLink>
                  </div>
                )}
                {languagePicker && (
                  <div>
                    <LanguagePicker languagePicker={languagePicker} />
                  </div>
                )}
              </TopColumn>
            </TopContentContainer>
          </TopSection>
          <ContentContainer>
            <Container>
              <StyledKexLink href={languageRoute ? `/${languageRoute}/` : '/'}>
                <Image src={logo?.src} alt={logo?.alt} />
              </StyledKexLink>
            </Container>
          </ContentContainer>
          <MobileQuickSearch />
        </Wrapper>
        <MobileMenu />
      </HeaderTag>
    </>
  );
}

export default MobileHeader;

const Placeholder = styled('div', {
  w: '100%',
  pt: '88px',
});

const ResellerLink = styled(KexLink, {
  fontWeight: '$fw400',
  textDecoration: 'underline',
});

const HeaderTag = styled('header', {
  zIndex: '$Header',
  backgroundColor: '$F7F6F5',
  position: 'fixed',
  t: 0,
  w: '100%',
});

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& img': {
    maxW: '50%',
  },
});

const Wrapper = styled('div', {
  position: 'relative',
  zIndex: '$HeaderMobile',
  backgroundColor: '#F7F6F5',
  display: 'flex',
  flexWrap: 'wrap',
  pb: '20px',
});

const StyledKexLink = styled(KexLink, {
  mx: 'auto',
  my: 0.25,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const TopSection = styled('div', {
  background: '#DDDAD8',
  w: '100%',
  px: '20px',
  mb: '20px',
});

const TopContentContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  mx: 'auto',
});

const TopColumn = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '12px',
  fw: '$fw400',
  lineHeight: '24px',
  letterSpacing: '1px',
  gap: '10px',
  w: '100%',
  '& a': {
    fontSize: '12px',
    fw: '$fw400',
    lineHeight: '24px',
    letterSpacing: '1px',
  },
  '& div': {
    alignSelf: 'center',
  },
});
